/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';

// Material UI
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  withStyles
} from '@material-ui/core';

import { ExpandLess, ExpandMore } from '@material-ui/icons';

// Utils
import { compose } from '@Utils';

// Components
import SubitemsComponent from './Subitems';

// Styles
import styles from './styles';

export class MenuSection extends Component {
  state = {
    isOpen: false
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    currentPath: PropTypes.string.isRequired,
    section: PropTypes.object
  };

  expandHandler = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  componentDidMount() {
    const { section, currentPath } = this.props;
    this.setState({
      isOpen: !!section.routes.find(route => currentPath.includes(route.path))
    });
  }

  render() {
    const { classes, section, currentPath } = this.props;
    const { isOpen } = this.state;

    return (
      <div
        className={classNames(classes.wrapper, isOpen && classes.activeWrapper)}
      >
        <ListItem
          onClick={this.expandHandler}
          classes={{
            root: classes.headingRoot,
            button: classes.headingButton
          }}
        >
          <ListItemIcon
            className={classNames(
              classes.headingIcon,
              isOpen ? classes.activeHeadingIcon : null
            )}
          >
            {section.icon}
          </ListItemIcon>
          <ListItemText
            primary={section.title}
            classes={{ primary: classes.headingPrimaryText }}
          />
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {section.routes ? (
          <Collapse in={isOpen} timeout={0} unmountOnExit>
            <SubitemsComponent
              routes={section.routes}
              currentPath={currentPath}
            />
          </Collapse>
        ) : null}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  observer
)(MenuSection);
