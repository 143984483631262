// Core
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  wrapper: {
    overflow: 'scroll',
    marginBottom: '8px',
    flexGrow: 1,
    height: '1px',
    width: '100%'
  }
}));

export default useStyles;
