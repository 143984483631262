/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import qs from 'query-string';

// Components
import { MainContentWrapper, PieChart } from '@CommonScene';
import Card from '@ParticipantsManagementScene/scenes/Dashboard/components/Card';
import RoleLegend from '@CommonScene/PieChart/components/RoleLegend';
import StatusLegend from '@CommonScene/PieChart/components/StatusLegend';

// Material UI
import { Grid } from '@material-ui/core';

// Utils
import { compose, downloadFile } from '@Utils';
import { groupBy } from 'lodash';

// Constants
import { GENERATE_SUBSET_OF_DATASET } from '@Utils/constans/permissions';

const reportsAliases = {
  byStatuses: 'PARTICIPATION',
  byRoles: 'PARTICIPATION_BY_ROLE'
};

export class ParticipationDashboard extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    AuthStore: PropTypes.object.isRequired,
    FilterStore: PropTypes.object.isRequired,
    BaseReportingStore: PropTypes.object.isRequired,
    DashboardReportStore: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.getReport();
  }

  componentWillUnmount() {
    this.props.FilterStore.reset();
    this.props.DashboardReportStore.resetDetails();
  }

  getReport() {
    const {
      history: { location },
      DashboardReportStore: { getReport, setFilters }
    } = this.props;

    const reportType = qs.parse(location.search).type || 'byRoles';
    const reportAlias = reportsAliases[reportType];

    this.props.FilterStore.reset();
    this.props.DashboardReportStore.resetDetails();

    return getReport(reportAlias, {
      roles: [],
      statuses: []
    }).then(() => {
      setFilters(['status', 'role']);
    });
  }

  getHeaderOptions() {
    const { t } = this.props;

    return {
      title: t('can.participantsManagement.participationDashboard.title')
    };
  }

  getFilterOptions() {
    return {
      applyHandler: this.applyFilter
    };
  }

  getTabsOptions() {
    const type = qs.parse(this.props.location.search).type || 'byRoles';
    return {
      tabs: [
        {
          title: 'By roles',
          isActive: type === 'byRoles',
          onClick: () => {
            this.props.history.push({
              pathname: '/dashboard',
              search: qs.stringify({ type: 'byRoles' })
            });
            this.getReport();
          }
        },
        {
          title: 'By statuses',
          isActive: type === 'byStatuses',
          onClick: () => {
            this.props.history.push({
              pathname: '/dashboard',
              search: qs.stringify({ type: 'byStatuses' })
            });
            this.getReport();
          }
        }
      ]
    };
  }

  getSpeedDialMenuOptions = () => {
    const {
      BaseReportingStore: { getFileContent },
      DashboardReportStore: { pageParams, details },
      FilterStore: { selectedFilters },
      AuthStore: { permissions },
      CommonStore: { pending },
      location
    } = this.props;

    const reportType = qs.parse(location.search).type || 'byRoles';
    const reportAlias = reportsAliases[reportType];

    return {
      isShown: permissions.includes(GENERATE_SUBSET_OF_DATASET),
      isDisabled: pending || !details.length,
      onClickHandler: documentFormat => {
        getFileContent(
          reportAlias,
          {
            ...pageParams,
            roles: selectedFilters.role,
            statuses: selectedFilters.status,
            skip: 0,
            limit: details.length
          },
          documentFormat
        ).then(() => {
          downloadFile(documentFormat);
        });
      }
    };
  };

  applyFilter = () => {
    const {
      FilterStore: { selectedFilters, resendRequestForFilters },
      DashboardReportStore: { getReport },
      location
    } = this.props;

    const reportType = qs.parse(location.search).type || 'byRoles';
    const reportAlias = reportsAliases[reportType];

    if (resendRequestForFilters) {
      this.getReport().then(() => {
        getReport(reportAlias, {
          statuses: selectedFilters.status,
          roles: selectedFilters.role
        });
      });
    }

    return getReport(reportAlias, {
      statuses: selectedFilters.status,
      roles: selectedFilters.role
    });
  };

  // TODO: needs refactor
  getSortedData = (items, sortBy) =>
    items.sort((a, b) => {
      if (a[sortBy] < b[sortBy]) {
        return -1;
      }
      if (a[sortBy] > b[sortBy]) {
        return 1;
      }
      return 0;
    });

  render() {
    const {
      DashboardReportStore: {
        report: { aggregation, details }
      }
    } = this.props;

    // TODO: Refactor code below

    const reportType = qs.parse(this.props.location.search).type || 'byRoles';
    const reportTypeMainKey = reportType === 'byRoles' ? 'role' : 'status';

    const groupedDetails = groupBy(details, reportTypeMainKey);
    const groupedDetailsKeys = Object.keys(groupedDetails);

    const getPieChartSortingIndexes = key => {
      const indexes = {
        SUSPENDED: {
          index: 4,
          color: '#FE4848'
        },
        ASSIGNED_WALLET: {
          index: 1,
          color: '#6FC960'
        },
        INVITATION_EXPIRED: {
          index: 5,
          color: '#FE4848'
        },
        INVITATION_PENDING: {
          index: 2,
          color: '#FFA91E'
        },
        CREATED: {
          index: 3,
          color: '#9B51E0'
        },
        'Member role': {
          index: 1,
          color: '#6FC960'
        },
        'Care Coordinator': {
          index: 3,
          color: '#F35598'
        },
        Physician: {
          index: 2,
          color: '#FFA91E'
        },
        Practice: {
          index: 4,
          color: '#4657ED'
        }
      };
      return indexes[key] || {};
    };

    const getCardsSortingIndexes = key => {
      const indexes = {
        SUSPENDED: {
          index: 2,
          color: '#FE4848'
        },
        ASSIGNED_WALLET: {
          index: 1,
          color: '#6FC960'
        },
        INVITATION_EXPIRED: {
          index: 4,
          color: '#FE4848'
        },
        INVITATION_PENDING: {
          index: 3,
          color: '#FFA91E'
        },
        CREATED: {
          index: 5,
          color: '#9B51E0'
        },
        'Member role': {
          index: 1,
          color: '#6FC960'
        },
        'Care Coordinator': {
          index: 2,
          color: '#F35598'
        },
        Physician: {
          index: 3,
          color: '#FFA91E'
        },
        Practice: {
          index: 4,
          color: '#4657ED'
        }
      };
      return indexes[key] || {};
    };

    const pieChartIndexedData = aggregation.map(item => {
      return {
        ...item,
        index: getPieChartSortingIndexes(item[reportTypeMainKey]).index,
        color: getPieChartSortingIndexes(item[reportTypeMainKey]).color
      };
    });

    const cardsIndexedData = groupedDetailsKeys.map(key => {
      return {
        [reportTypeMainKey]: key,
        index: getCardsSortingIndexes(key).index,
        color: getCardsSortingIndexes(key).color
      };
    });

    return (
      <MainContentWrapper
        headerOptions={this.getHeaderOptions()}
        filterOptions={this.getFilterOptions()}
        speedDialMenuOptions={this.getSpeedDialMenuOptions()}
        tabsOptions={this.getTabsOptions()}
      >
        <PieChart
          data={this.getSortedData(pieChartIndexedData, 'index')}
          nameKey={reportTypeMainKey}
          valueKey="total"
          Legend={reportTypeMainKey === 'role' ? RoleLegend : StatusLegend}
        />
        <Grid container spacing={2}>
          {this.getSortedData(cardsIndexedData, 'index').map(item => {
            const title = item[reportTypeMainKey];
            return (
              <Card
                key={shortid.generate()}
                title={title}
                items={this.getSortedData(groupedDetails[title], 'status')}
                reportTypeMainKey={reportTypeMainKey}
              />
            );
          })}
        </Grid>
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  inject(
    'AuthStore',
    'BaseReportingStore',
    'DashboardReportStore',
    'FilterStore',
    'CommonStore'
  ),
  observer
)(ParticipationDashboard);
