/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

// Material UI
import { Grid } from '@material-ui/core';

// Components
import QuestionsBlocks from '@AuthScene/scenes/SignUp/components/RecoveryQuestions/components/QuestionsBlocks';
import SignupWrapper from '@AuthScene/scenes/common/SignupWrapper';

// Utils
import { compose } from '@Utils';
import { useStore } from '@Utils/hooks';

// Styles
import useStyles from './styles';

const Questions = ({ history }) => {
  const classes = useStyles();
  const {
    SignUpStore: { setNextStep, resetSteps, setSerializedQestions },
    AuthStore: { forms, recoveryQuestions, getRecoveryQuestions }
  } = useStore();

  useEffect(() => {
    if (!forms.signInForm.fields.password.value) {
      history.push('/login');
      return;
    }
    getRecoveryQuestions();
  }, []);

  const onRightButtonClickHandler = useCallback(() => {
    const recoveryQuestionsFormFields = forms.recoveryQuestionsForm.fields;
    const formData = [
      {
        questionId: recoveryQuestionsFormFields.question_1.value,
        answer: recoveryQuestionsFormFields.answer_1.value
      },
      {
        questionId: recoveryQuestionsFormFields.question_2.value,
        answer: recoveryQuestionsFormFields.answer_2.value
      },
      {
        questionId: recoveryQuestionsFormFields.question_3.value,
        answer: recoveryQuestionsFormFields.answer_3.value
      }
    ];

    const serializedQestionsString = recoveryQuestions
      .map(question => {
        const selectedQuestion = formData.find(
          item => item.questionId === question.id
        );
        if (selectedQuestion) {
          return {
            id: selectedQuestion.questionId,
            answer: selectedQuestion.answer.toLowerCase()
          };
        }
        return { id: question.id, answer: 'EMPTY' };
      })
      .sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
      .map(item => `"${item.id}":"${item.answer}"`)
      .join('|')
      .replace(/\s+/g, '');

    setSerializedQestions(serializedQestionsString);
    setNextStep();
  });

  const onLeftButtonClickHandler = useCallback(() => {
    history.push('/login');
    resetSteps();
  });

  return (
    <SignupWrapper
      title="Choose recovery questions"
      subtitle="If you ever forget your password, these questions will be used to verify your identity so that you can reset your password."
      onLeftButtonClick={onLeftButtonClickHandler}
      onRightButtonClick={onRightButtonClickHandler}
      disabledRightButton={!forms.recoveryQuestionsForm.meta.isValid}
      withStepper={false}
    >
      <Grid item className={classes.wrapper}>
        <QuestionsBlocks />
      </Grid>
    </SignupWrapper>
  );
};

Questions.propTypes = {
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  observer
)(Questions);
