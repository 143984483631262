/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Utils
import { GENERATE_SUBSET_OF_DATASET } from '@Utils/constans/permissions';
import { downloadFile } from '@Utils';

const withDownloadButtonOptions = WrappedComponent => {
  return class extends Component {
    static propTypes = {
      t: PropTypes.func.isRequired,
      BaseReportingStore: PropTypes.object.isRequired,
      FilterStore: PropTypes.object.isRequired,
      AuthStore: PropTypes.object.isRequired,
      CommonStore: PropTypes.object.isRequired
    };

    getSpeedDialMenuOptions = ({
      reportId,
      pageParams,
      details,
      aggregation
    }) => {
      const {
        BaseReportingStore: { getFileContent },
        FilterStore: { selectedFilters },
        AuthStore: { permissions },
        CommonStore: { pending }
      } = this.props;

      return {
        isShown: permissions.includes(GENERATE_SUBSET_OF_DATASET),
        isDisabled: pending || !details.length,
        onClickHandler: documentFormat =>
          getFileContent(
            reportId,
            {
              ...pageParams,
              roles: selectedFilters.role,
              // Get all items without skipping
              skip: 0,
              limit: aggregation.reduce((acc, val) => val.total + acc, 0)
            },
            documentFormat
          ).then(() => {
            downloadFile(documentFormat);
          })
      };
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          getSpeedDialMenuOptions={this.getSpeedDialMenuOptions}
        />
      );
    }
  };
};

export default withDownloadButtonOptions;
