/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import shortid from 'shortid';
import i18n from 'i18next';

// Components
import UsersStatus from '@CommonScene/UsersStatus';
import CellWithTooltip from '@CommonScene/DataTable/BodyRow/CellWithTooltip';

// Stores
import GroupStore from '@Stores/GroupStore';
import NetworkStore from '@Stores/NetworkStore';

// Utils
import {
  formatPhoneNumber,
  renderUserRolesName,
  formatUserStatus,
  formatDate
} from '@Utils/formatting';
import { handleRowClick } from '@Utils';

export const setOrder = orderBy => () => {
  NetworkStore.setOrder({
    groupId: GroupStore.groupInfo.id,
    orderBy
  });
};

export const cellWithTooltip = data => <CellWithTooltip data={data} />;

export const tableConfig = {
  defaultOrderBy: 'first_name',
  handleRowClick: handleRowClick('/networks/view/'),
  rowIdKey: 'id',
  headings: [
    {
      id: 'first_name',
      title: i18n.t('common.name'),
      sortable: true,
      cb: setOrder('firstName')
    },
    {
      id: 'phone_number',
      title: i18n.t('common.cellPhone'),
      sortable: true,
      cb: setOrder('phoneNumber')
    },
    {
      id: 'care_wallet_id',
      title: i18n.t('common.walletId'),
      sortable: true,
      cb: setOrder('careWalletId')
    },
    i18n.t('mwAdmin.networkParticipants.assignedRoles'),
    <UsersStatus key={shortid.generate()} />,
    {
      id: 'updated_at',
      title: i18n.t('common.updated'),
      sortable: true,
      cb: setOrder('updatedAt')
    }
  ],
  rowCells: [
    ['first_name', 'last_name'],
    { key: 'phone_number', cb: formatPhoneNumber },
    {
      key: 'care_wallet_id',
      cb: cellWithTooltip
    },
    { key: 'roles', cb: renderUserRolesName },
    { key: 'status', cb: formatUserStatus },
    { key: 'updated_at', cb: formatDate }
  ]
};
