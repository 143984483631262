/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';

// Components
import { MainContentWrapper, BlockWrapper } from '@CommonScene';
import InviteToRoleForm from '@ParticipantsManagementScene/scenes/NewParticipant/components/InviteToRoleForm';

// Utils
import { compose } from '@Utils';
import { getParticipantManagementPath } from '@Utils/constans/paths';

// Styles
import styles from './styles';

export class NewParticipant extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    InvitationStore: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired,
    UserRolesStore: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {
      UserRolesStore: { getRoles }
    } = this.props;

    getRoles(null, 3000, { role_class: 'SOLUTION' });
  }

  componentWillUnmount() {
    const { InvitationStore, UserRolesStore } = this.props;

    InvitationStore.resetForms();
    UserRolesStore.reset();
  }

  showErrorMessage = error => {
    const {
      CommonStore: { setModalOptions }
    } = this.props;

    setModalOptions({
      modalName: 'ModalInvitationError',
      modalProps: {
        error
      }
    });
  };

  handleSubmitForm = e => {
    e.preventDefault();

    const {
      InvitationStore: { inviteUser, resetForms },
      history
    } = this.props;

    return inviteUser()
      .then(() => {
        history.replace(getParticipantManagementPath.participants());
        resetForms();
      })
      .catch(error => {
        this.showErrorMessage(error.response.body.errors[0].message);
      });
  };

  getHeaderOptions() {
    const { t } = this.props;

    return {
      title: t('mwAdmin.invitations.newParticipant')
    };
  }

  getFormConfig() {
    const {
      t,
      InvitationStore: { forms, onFieldChange }
    } = this.props;

    return {
      onFieldChange,
      name: 'inviteCreateForm',
      fields: forms.inviteCreateForm.fields,
      isValid: forms.inviteCreateForm.meta.isValid,
      submitButtonText: t('common.create'),
      cancelLink: getParticipantManagementPath.participants()
    };
  }

  render() {
    const {
      classes,
      UserRolesStore: { roles }
    } = this.props;

    return (
      <MainContentWrapper headerOptions={this.getHeaderOptions()}>
        <BlockWrapper className={classes.middleBlock}>
          <InviteToRoleForm
            formConfig={this.getFormConfig()}
            userRoles={roles}
            handleSubmitForm={this.handleSubmitForm}
          />
        </BlockWrapper>
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
  inject('InvitationStore', 'CommonStore', 'UserRolesStore'),
  observer
)(NewParticipant);
