/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Material UI
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Components
import { PhoneNumberField, SelectOptions } from '@CommonScene';
import InputField from '@CommonScene/InputField';
import ButtonsBlock from '@ParticipantsManagementScene/scenes/NewParticipant/components/ButtonsBlock';
import SendInviteCheckbox from '@ParticipantsManagementScene/scenes/NewParticipant/components/SendinviteCheckbox';
import InfoIcon from '@CommonScene/modals/common/InfoIcon';

// Utils
import { compose } from '@Utils';
import { validationKeys } from '@Utils/constans';

// Styles
import styles from './styles';

const INPUTS = ['firstName', 'lastName', 'email'];

export class InviteToRoleForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmitForm: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    userRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
    formConfig: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired
  };

  handleCancel = () => {
    const { t, CommonStore, formConfig, history } = this.props;

    if (formConfig.fields.role.value || formConfig.fields.phone.value) {
      CommonStore.setModalOptions({
        modalName: 'ModalWarning',
        modalProps: {
          title: t('modals.confirmCancellation'),
          text: t('modals.discardChanges'),
          Icon: InfoIcon,
          borderColor: 'yellowBorder',
          handleConfirmAction: history.goBack,
          confirmBtn: t('common.yes'),
          cancelBtn: t('common.no')
        }
      });
      return;
    }

    history.goBack();
  };

  handleOnChange = e => {
    const { formConfig } = this.props;
    formConfig.onFieldChange(e.target.name, e.target.value, formConfig.name);
  };

  isItemDisabled = currentRole => {
    if (currentRole.pipeline) {
      return currentRole.pipeline.pipes.find(
        pipe => !pipe.mode.includes('INV_CODE_PHONE')
      );
    }
    return false;
  };

  render() {
    const { formConfig, t, userRoles, handleSubmitForm } = this.props;

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs>
            <SelectOptions
              name="role"
              label={`${t('mwAdmin.invitations.selectRole')} *`}
              items={userRoles}
              disableItemCallback={this.isItemDisabled}
              value={formConfig.fields.role.value}
              displayEmpty
              onChange={this.handleOnChange}
              placeholder={t('mwAdmin.invitations.selectRole')}
            />
            <PhoneNumberField
              name="phone"
              label={`${t('common.phone')} *`}
              error={t(validationKeys[formConfig.fields.phone.error])}
              value={formConfig.fields.phone.value}
              onChange={this.handleOnChange}
              placeholder=""
            />
            {INPUTS.map(input => (
              <InputField
                key={formConfig.fields[input].name}
                config={formConfig.fields[input]}
              />
            ))}
            <SendInviteCheckbox
              label={formConfig.fields.sendCheckbox.label}
              checked={formConfig.fields.sendCheckbox.value}
              onChange={formConfig.fields.sendCheckbox.onChange}
            />
          </Grid>
          <ButtonsBlock
            formConfig={formConfig}
            handleCancel={this.handleCancel}
            handleSubmitForm={handleSubmitForm}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  inject('CommonStore'),
  observer
)(InviteToRoleForm);
