/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import i18n from 'i18next';

// Utils
import { tableConfig as baseTableConfig } from '@Assets/config/tables/groupParticipants';

// Material UI
import { Create, Delete, Lock, LockOpen } from '@material-ui/icons';

// Stores
import NetworkStore from '@Stores/NetworkStore';
import CommonStore from '@Stores/CommonStore';

export const setOrder = orderBy => () => NetworkStore.setOrder({ orderBy });

export const handleModalClick = modalOptions => ({ rowId }) => {
  NetworkStore.setUserId(rowId);
  CommonStore.setModalOptions(modalOptions);
};

export const handleRedirectClick = ({ rowId, redirectTo }) => {
  redirectTo(`/networks/edit/${rowId}`);
};

export const tableConfig = {
  defaultOrder: 'asc',
  hasActions: true,
  ...baseTableConfig,
  actionsConfig: {
    keyForActivation: 'status',
    isDisabled: value => value === 'REMOVED',
    filterMenuItems: exlude => {
      if (exlude !== 'INVITED' && exlude !== 'REMOVED') {
        // TODO: Fix it. Use correct 'this' context (this.actionsConfig.menuItems)
        return tableConfig.actionsConfig.menuItems.filter(
          item => item.status !== exlude
        );
      }
      return tableConfig.actionsConfig.menuItems.filter(item => !item.status);
    },
    filterKey: 'status',
    menuItems: [
      {
        name: i18n.t('mwAdmin.networkParticipants.editProfile'),
        icon: <Create />,
        isRedirect: true,
        handleClick: handleRedirectClick
      },
      {
        name: i18n.t('mwAdmin.networkParticipants.block'),
        icon: <Lock />,
        status: 'BLOCKED',
        handleClick: handleModalClick({
          modalName: 'ConfirmationAction',
          modalProps: {
            handleConfirmAction: NetworkStore.setBlockUser
          }
        })
      },
      {
        name: i18n.t('mwAdmin.networkParticipants.unblock'),
        icon: <LockOpen />,
        status: 'ACTIVE',
        handleClick: handleModalClick({
          modalName: 'ConfirmationAction',
          modalProps: {
            handleConfirmAction: NetworkStore.setUnblockUser
          }
        })
      },
      {
        name: i18n.t('common.delete'),
        icon: <Delete />,
        handleClick: handleModalClick({
          modalName: 'ModalDeleteConfirmation',
          modalProps: {
            handleConfirmAction: NetworkStore.deleteUser,
            handleCancelAction: NetworkStore.resetForms
          }
        })
      }
    ]
  }
};
