/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Material UI
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';

// Utils
import useLocalStorage from '@Utils/hooks/useLocalStorage';
import { formatTimer } from '@Utils/formatting';

// styles
import useStyles from './styles';

const TIMER_VALUE = 60000; // 1min
const TIME_COUNT = 1000; // 1s

const ResendCode = ({ onClick, phone }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [timerValue, setTimerValue] = useLocalStorage('resendTimer');
  const [timer, setTimer] = useState(() =>
    Math.round((timerValue - Date.now()) / 1000)
  );

  const startTimeOut = useCallback(
    time =>
      setTimeout(() => {
        setTimer(time - 1);
      }, TIME_COUNT),
    []
  );

  useEffect(() => {
    if (timer > 0) {
      startTimeOut(timer);
    }
  }, [timer]);

  const onClickHandler = useCallback(() => {
    setTimer(TIMER_VALUE / 1000);
    setTimerValue(Date.now() + TIMER_VALUE);
    onClick();
  }, [timer, setTimer, setTimerValue]);

  return (
    <Grid
      container
      classes={{ root: classes.section }}
      justify="space-between"
      alignItems="center"
    >
      {timer <= 0 ? (
        <Fragment>
          <Grid item classes={{ root: classes.text }}>
            {`${t('auth.resetPassword.stage2.sendCodeText')} ${phone}`}
          </Grid>
          <Grid item>
            <Button onClick={onClickHandler} classes={{ root: classes.button }}>
              {t('common.resend')}
            </Button>
          </Grid>
        </Fragment>
      ) : (
        <Grid item classes={{ root: classes.text }}>
          {`${t('auth.resetPassword.stage2.codeWasResent')} ${formatTimer(
            timer
          )}`}
        </Grid>
      )}
    </Grid>
  );
};

ResendCode.propTypes = {
  onClick: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired
};

export default ResendCode;
