/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
// Using here toJS method because 'inputProps' prop can be observable
import { toJS } from 'mobx';

// Material UI
import { TextField } from '@material-ui/core';

// Utils
import { compose } from '@Utils';

// Styles
import useStyles from './styles';

export const TextFieldComponent = ({
  onChange,
  error,
  inputProps,
  InputProps,
  ...restProps
}) => {
  const classes = useStyles();
  return (
    <TextField
      id="text-field"
      classes={{ root: classes.root }}
      fullWidth
      error={!!error}
      onChange={e => onChange(e)}
      helperText={error}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.input,
          focused: classes.focused,
          error: classes.error
        },
        ...InputProps
      }}
      inputProps={toJS(inputProps)}
      {...restProps}
    />
  );
};

TextFieldComponent.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object
};

TextFieldComponent.defaultProps = {
  value: '',
  error: null,
  inputProps: {}
};

export default compose(observer)(TextFieldComponent);
