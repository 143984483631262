/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';

// Components
import { DataTable } from '@CommonScene';

// Material UI
import { Grid, Typography, Button } from '@material-ui/core';

// Config
import { tableConfig } from '@Assets/config/tables/termsAndConditions';

// Utils
import { compose } from '@Utils';
import { CREATE_TERMS_AND_CONDITIONS } from '@Utils/constans/permissions';
import { getRolesPath } from '@Utils/constans/paths';

export const TermsAndConditionsTable = ({
  hasActiveColumn,
  tableData,
  match,
  last,
  fetchTableData,
  permissions
}) => {
  const config = { ...tableConfig, hasActions: hasActiveColumn };

  const {
    params: { role }
  } = match;

  useEffect(() => {
    // when tableConfig created - AuthStore is undefined
    tableConfig.updateMenuItems();
  }, []);

  return (
    <Fragment>
      <Typography variant="h6">Terms and conditions</Typography>
      {permissions.includes(CREATE_TERMS_AND_CONDITIONS) ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to={getRolesPath.userRolesTermsAndConditionsNew({ roleId: role })}
            >
              new
            </Button>
          </Grid>
        </Grid>
      ) : null}
      <DataTable
        tableConfig={config}
        tableData={tableData}
        fetchTableData={fetchTableData}
        hasMoreData={!last}
      />
    </Fragment>
  );
};

TermsAndConditionsTable.defaultProps = {
  hasActiveColumn: false,
  last: true,
  fetchTableData: () => {
    // default value
  }
};

TermsAndConditionsTable.propTypes = {
  hasActiveColumn: PropTypes.bool,
  last: PropTypes.bool,
  tableData: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  fetchTableData: PropTypes.func,
  permissions: PropTypes.array.isRequired
};

export default compose(
  withRouter,
  observer
)(TermsAndConditionsTable);
