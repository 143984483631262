/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

// Material UI
import { Grid } from '@material-ui/core';

// Components
import SignupWrapper from '@AuthScene/scenes/common/SignupWrapper';

// Utils
import { compose } from '@Utils';
import { useStore } from '@Utils/hooks';
import {
  transformMnemonicToPrivateKey,
  normalizePassword,
  convertToSha256,
  encrypt
} from '@Utils/cryptographic';

// Styles
import useStyles from './styles';

const Phrase = ({ history }) => {
  const classes = useStyles();
  const {
    SignUpStore: { mnemonicPhrase, generateMnemonicPhrase, serializedQestions },
    AuthStore: { forms, createContainer, getContainer, recoveryQuestionsIds },
    CommonStore: { pending }
  } = useStore();

  useEffect(() => {
    generateMnemonicPhrase();
  }, []);

  const onRightButtonClickHandler = useCallback(() => {
    const privateKey = transformMnemonicToPrivateKey(mnemonicPhrase);
    const passwordPassPhrase = normalizePassword(
      convertToSha256(forms.signInForm.fields.password.value)
    );
    const questionsPassPhrase = normalizePassword(
      convertToSha256(serializedQestions)
    );

    const defaultContainer = encrypt(privateKey, passwordPassPhrase);
    const restoreContainer = encrypt(privateKey, questionsPassPhrase);

    // TODO: specify correct values for app_version and enc_version fields
    const payload = {
      app_version: 'default_app_version',
      challenges: recoveryQuestionsIds,
      enc_version: 'default_enc_version',
      payloads: [
        {
          payload: defaultContainer,
          type: 'DEFAULT'
        },
        {
          payload: restoreContainer,
          type: 'RESTORE'
        }
      ]
    };

    createContainer(payload).then(() => {
      getContainer()
        .then(data => {
          window.localStorage.setItem(
            'container',
            JSON.stringify(data.container)
          );
          history.push('/dashboard');
        })
        .catch(e => {
          if (e.response.status === 404) {
            history.push('/recovery');
          }
        });
    });
  });

  return (
    <SignupWrapper
      title="Backup recovery phrase"
      subtitle="The recovery phrase below is one more way to access your account
      in case you forget password. Store recovery phrase in safe place."
      onRightButtonClick={onRightButtonClickHandler}
      disabledRightButton={pending}
      rightButtonText="I saved the phrase"
      withStepper={false}
    >
      <Grid item className={classes.wrapper}>
        <div className={classes.phrase}>{mnemonicPhrase}</div>
      </Grid>
    </SignupWrapper>
  );
};

Phrase.propTypes = {
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  observer
)(Phrase);
