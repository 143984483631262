/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// Material UI
import { withStyles } from '@material-ui/core';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';

import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { Export, Close, PDF, Excel } from '@Utils/constans/icons';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

const SpeedDialMenu = ({ classes, options }) => {
  const { onClickHandler } = options;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (event, reason) => {
    if (reason !== 'focus') {
      setOpen(true);
    }
  };

  return (
    <SpeedDial
      classes={{
        root: classes.root,
        fab: classes.fab,
        actions: classes.actions
      }}
      ariaLabel="SpeedDial"
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      direction="left"
      icon={
        <SpeedDialIcon
          classes={{
            root: classes.mainIconRoot
          }}
          icon={<Export />}
          openIcon={<Close />}
        />
      }
    >
      <SpeedDialAction
        classes={{
          fab: classes.fab
        }}
        icon={<PDF />}
        tooltipTitle="Export to PDF"
        tooltipPlacement="top"
        onClick={() => {
          handleClose();
          onClickHandler('pdf');
        }}
      />
      <SpeedDialAction
        classes={{
          fab: classes.fab
        }}
        icon={<Excel />}
        tooltipTitle="Export to Excel"
        tooltipPlacement="top"
        onClick={() => {
          handleClose();
          onClickHandler('xls');
        }}
      />
    </SpeedDial>
  );
};

SpeedDialMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  observer
)(SpeedDialMenu);
