/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { observer } from 'mobx-react';

// Material UI
import { TableRow, TableCell, withStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

const Loader = ({ classes, rowsCount, cellsCount }) => {
  const rows = [];
  for (let i = 0; i < rowsCount; i++) {
    const cells = [];
    for (let j = 0; j < cellsCount; j++) {
      cells.push(
        <TableCell key={shortid.generate()} className={classes.cell}>
          <Skeleton
            classes={{
              root: classes.skeleton
            }}
          />
        </TableCell>
      );
    }
    const row = <TableRow key={shortid.generate()}>{cells}</TableRow>;
    rows.push(row);
  }

  return rows;
};

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
  rowsCount: PropTypes.number,
  cellsCount: PropTypes.number
};

Loader.defaultProps = {
  rowsCount: 5,
  cellsCount: 5
};

export default compose(
  withStyles(styles),
  observer
)(Loader);
