/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Material UI
import { Typography } from '@material-ui/core';

// Components
import { PasswordField } from '@CommonScene';
import ErrorSection from '@AuthScene/scenes/common/ErrorSection';

// Styles
import useStyles from './styles';

const PasswordFields = ({
  errors,
  passwordLabel,
  confirmLabel,
  passwordValue,
  confirmValue,
  onFieldChange,
  formName
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmationPassword, setErrorConfirmationPassword] = useState(
    false
  );

  const preventDefault = useCallback(event => event.preventDefault());

  const getErrors = useCallback(() => {
    if (!errorConfirmationPassword && !errors) {
      return null;
    }

    if (errors) {
      return errors;
    }

    return t('auth.resetPassword.stage4.matchError');
  }, [errors, errorConfirmationPassword]);

  const checkValidPassword = useCallback(
    (value = passwordValue) => {
      const strongRegex = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})'
      );

      setErrorPassword(!strongRegex.test(value));
    },
    [passwordValue, setErrorPassword]
  );

  const passwordMatch = useCallback(
    (value = confirmValue) => {
      setErrorConfirmationPassword(value !== passwordValue);
    },
    [passwordValue, confirmValue, setErrorConfirmationPassword]
  );

  const disabledConfirmPasswordField = useCallback(() => {
    return !!(!passwordValue || errorPassword);
  }, [errorPassword, passwordValue]);

  const onChangePassword = event => {
    onFieldChange(event.target.name, event.target.value, formName);
    if (errorPassword) {
      checkValidPassword(event.target.value);
    }

    if (passwordValue.length === confirmValue.length) {
      passwordMatch();
    }
  };

  const onChangeConfirm = event => {
    onFieldChange(event.target.name, event.target.value, formName);

    if (errorConfirmationPassword) {
      passwordMatch(event.target.value);
    }

    if (passwordValue.length === confirmValue.length) {
      passwordMatch();
    }
  };

  return (
    <Fragment>
      <PasswordField
        id="password-input"
        name="password"
        label={passwordLabel || t('auth.resetPassword.stage4.newPasswordLabel')}
        error={errorPassword}
        inputProps={{ maxLength: 40 }}
        value={passwordValue}
        onCopy={preventDefault}
        onChange={onChangePassword}
        onBlur={() => checkValidPassword()}
      />

      {errorPassword ? (
        <ErrorSection
          errors={t('auth.resetPassword.stage4.passwordDescriptionError')}
        />
      ) : (
        <Typography align="left" className={classes.description}>
          {t('auth.resetPassword.stage4.passwordDescription')}
        </Typography>
      )}

      <PasswordField
        id="confirmation-password-input"
        name="passwordConfirm"
        label={
          confirmLabel || t('auth.resetPassword.stage4.confirmPasswordLabel')
        }
        error={errorConfirmationPassword}
        inputProps={{ maxLength: 40 }}
        value={confirmValue}
        onCopy={preventDefault}
        onPaste={preventDefault}
        onChange={onChangeConfirm}
        onBlur={() => passwordMatch()}
        disabled={disabledConfirmPasswordField()}
      />

      <ErrorSection errors={getErrors()} />
    </Fragment>
  );
};

PasswordFields.defaultProps = {
  formName: 'resetPassword'
};

PasswordFields.propTypes = {
  errors: PropTypes.string,
  passwordValue: PropTypes.string.isRequired,
  passwordLabel: PropTypes.string,
  confirmValue: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string,
  onFieldChange: PropTypes.func.isRequired,
  formName: PropTypes.string
};

export default PasswordFields;
