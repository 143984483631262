import Blockchain from '@Utils/blockchain/Blockchain';

export const getSignedEvent = event => {
  const hash = Blockchain.generateEventHash(
    event,
    '0x89dad48e2662b52127010d9cf7eded9f176d3d85'
  );
  const signature = Blockchain.signHash(
    hash,
    '72e7061c9bf7de0c666fbcc976e58a77d43b648c0d46477c9d27e89b6e6fcf83'
  );
  return { ...event, signature };
};
