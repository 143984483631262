/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';

// Material UI
import { Grid, withStyles } from '@material-ui/core';

// Components
import { BlockWrapper } from '@CommonScene';

// Utils
import { compose } from '@Utils';
import { addSpacesInNumber } from '@Utils/formatting';

// Styles
import styles from './styles';

class PieChart extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    nameKey: PropTypes.string.isRequired,
    valueKey: PropTypes.string.isRequired,
    Legend: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
      PropTypes.array,
      PropTypes.node
    ]).isRequired
  };

  state = {
    isChartReady: false
  };

  render() {
    const { nameKey, valueKey, Legend } = this.props;

    const { classes, data } = this.props;

    const clonedData = [...data];

    const chartData = clonedData.map(item => [
      item[nameKey],
      Number(item[valueKey])
    ]);

    const total = clonedData.reduce(
      (acc, val) => Number(val[valueKey]) + acc,
      0
    );

    const renderTotalBlock = () =>
      this.state.isChartReady ? (
        <div className={classes.totalCountWrapper}>
          <span>{addSpacesInNumber(total)}</span>
          <p>Total</p>
        </div>
      ) : null;

    const options = {
      pieSliceBorderColor: 'transparent',
      legend: 'none',
      pieHole: 0.7,
      slices: clonedData.map(item => {
        return {
          color: item.color
        };
      }),
      tooltip: {
        trigger: 'none'
      },
      pieSliceText: 'none',
      enableInteractivity: false,
      chartArea: {
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
      },
      fontName: 'Roboto'
    };

    return clonedData.length ? (
      <BlockWrapper withBottomMargin>
        <Grid container>
          <Grid item md={4} sm={12}>
            <Grid container justify="center">
              <Grid item className={classes.chartWrapper}>
                <Chart
                  chartType="PieChart"
                  data={[['item', 'value'], ...chartData]}
                  options={options}
                  width={'175px'}
                  height={'175px'}
                  chartEvents={[
                    {
                      eventName: 'ready',
                      callback: () => {
                        if (!this.state.isChartReady) {
                          this.setState({ isChartReady: true });
                        }
                      }
                    }
                  ]}
                />
                {renderTotalBlock()}
              </Grid>
            </Grid>
          </Grid>
          <Legend data={clonedData} />
        </Grid>
      </BlockWrapper>
    ) : null;
  }
}

export default compose(
  withStyles(styles),
  observer
)(PieChart);
