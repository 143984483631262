/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

// Material UI
import {
  Step,
  Stepper as StepperComponent,
  StepLabel
} from '@material-ui/core';

// Components
import StepConnector from '@AuthScene/scenes/common/StepConnector';
import StepIcon from '@AuthScene/scenes/common/StepIcon';

// Utils
import { compose } from '@Utils';
import { useStore } from '@Utils/hooks';

// Styles
import useStyles from './styles';

const Stepper = () => {
  const {
    SignUpStore: { step, stepsAmount }
  } = useStore();
  const steps = Array.from(new Array(stepsAmount), (val, index) => index + 1);
  const [activeStep, setActiveStep] = useState(step);
  const classes = useStyles();

  useEffect(() => {
    setActiveStep(step);
  }, [step]);

  return (
    <StepperComponent
      activeStep={activeStep}
      connector={<StepConnector />}
      classes={{ root: classes.root }}
    >
      {steps.map(s => (
        <Step key={s} classes={{ root: classes.stepRoot }}>
          <StepLabel
            classes={{ iconContainer: classes.circle }}
            StepIconComponent={StepIcon}
          >
            {'' /*  without it component didn't show step icons */}
          </StepLabel>
        </Step>
      ))}
    </StepperComponent>
  );
};

export default compose(observer)(Stepper);
