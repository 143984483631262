/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Material UI
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

// Components
import CustomFieldsForm from '@RolesScene/scenes/common/CustomFieldsForm';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

const AddFieldModal = ({
  classes,
  isOpen,
  handleCancelAction,
  onCustomFieldChange,
  inputOnChange,
  filteredSuggestion,
  existField,
  customField,
  handleConfirmAction
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      classes={{
        paper: classes.content
      }}
      open={isOpen}
      onClose={handleCancelAction}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {t('mwAdmin.modals.addField')}
      </DialogTitle>
      <IconButton
        id="cancel-icon"
        classes={{ root: classes.close }}
        onClick={handleCancelAction}
      >
        <Close />
      </IconButton>
      <DialogContent classes={{ root: classes.content }}>
        <CustomFieldsForm
          onChange={onCustomFieldChange}
          inputOnChange={inputOnChange}
          filteredSuggestions={filteredSuggestion}
          existField={existField}
        />
      </DialogContent>
      <DialogActions>
        <Button id="cancel-btn" onClick={handleCancelAction} color="default">
          {t('common.cancel')}
        </Button>
        <Button
          id="add-btn"
          disabled={customField === null || existField}
          variant="contained"
          color="secondary"
          onClick={handleConfirmAction}
        >
          {t('common.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddFieldModal.propTypes = {
  classes: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  handleCancelAction: PropTypes.func.isRequired,
  onCustomFieldChange: PropTypes.func.isRequired,
  inputOnChange: PropTypes.func.isRequired,
  filteredSuggestion: PropTypes.array,
  existField: PropTypes.bool,
  customField: PropTypes.object,
  handleConfirmAction: PropTypes.func.isRequired
};

export { AddFieldModal };

export default compose(
  withStyles(styles),
  observer
)(AddFieldModal);
