/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Material UI
import { Grid } from '@material-ui/core';

// Components
import { RadioButtons } from '@CommonScene';
import Divider from '@RolesScene/scenes/common/Divider';
import SectionTitle from '@RolesScene/scenes/common/SectionTitle';
import VerticalStepper from '@RolesScene/scenes/common/VerticalStepper';

// Utils
import { useStore } from '@Utils/hooks';
import { compose } from '@Utils';

const RoleSettings = ({ hideStepper, title, disabled }) => {
  const { t } = useTranslation();
  const {
    RoleStore: { joiningOption, setJoiningOption }
  } = useStore();

  const handleChange = useCallback(event => {
    setJoiningOption(event.target.value);
  });

  return (
    <VerticalStepper step={2} hideStepper={hideStepper}>
      <SectionTitle>{title}</SectionTitle>
      <Grid container>
        <Grid item xs={6}>
          <RadioButtons
            disabled={disabled}
            activeValue={joiningOption}
            handleChange={handleChange}
            buttons={[
              {
                label: t('mwAdmin.roles.invitationOnly'),
                value: 'invitation',
                hint: t('mwAdmin.roles.invitationOnlyHint')
              },
              {
                label: t('mwAdmin.roles.publicAccess'),
                value: 'invitationOrPublic',
                hint: t('mwAdmin.roles.publicAccessHint')
              }
            ]}
          />
        </Grid>
      </Grid>
      <Divider />
    </VerticalStepper>
  );
};

RoleSettings.propTypes = {
  disabled: PropTypes.bool,
  hideStepper: PropTypes.bool,
  title: PropTypes.string.isRequired
};

export default compose(observer)(RoleSettings);
