/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Material UI
import { Grid, withStyles } from '@material-ui/core';

// Icons
import { AbsentIcon } from '@Utils/constans/icons';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export const Attributes = ({ classes, attributes }) => {
  if (attributes) {
    return (
      <Grid container>
        <Grid item md={4}>
          {attributes.map(attribute => (
            <p key={shortid.generate()} className={classes.label}>
              {Object.keys(attribute)[0]}:
            </p>
          ))}
        </Grid>
        <Grid item md={8}>
          {attributes.map(attribute => (
            <p key={shortid.generate()} className={classes.value}>
              {attribute[Object.keys(attribute)[0]]}
            </p>
          ))}
        </Grid>
      </Grid>
    );
  }
  return <AbsentIcon />;
};

Attributes.propTypes = {
  classes: PropTypes.object.isRequired,
  attributes: PropTypes.array
};

export default compose(withStyles(styles))(Attributes);
