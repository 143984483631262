/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { inject, observer } from 'mobx-react';

// Components
import LoginWrapper from '@AuthScene/scenes/common/LoginWrapper';
import ResetPasswordPhone from '@AuthScene/scenes/ResetPassword/components/ResetPasswordPhoneForm';
import ResetPasswordCode from '@AuthScene/scenes/ResetPassword/components/ResetPasswordCodeForm';
import ResetPassword from '@AuthScene/scenes/ResetPassword/components/ResetPasswordForm';
import AccountRecovery from '@AuthScene/scenes/ResetPassword/components/AccountRecovery';

// Utils
import { compose } from '@Utils';

export class ResetPasswordLayout extends React.Component {
  static propTypes = {
    AuthStore: PropTypes.shape({
      resetPasswordStep: PropTypes.number
    })
  };

  static defaultProps = {
    resetPasswordStep: null
  };

  activeForm = () => {
    switch (this.props.AuthStore.resetPasswordStep) {
      case 1:
        return <ResetPasswordPhone />;
      case 2:
        return <ResetPasswordCode />;
      case 3:
        return <AccountRecovery />;
      case 4:
        return <ResetPassword />;
      default:
        return <ResetPasswordPhone />;
    }
  };

  render() {
    return <LoginWrapper>{this.activeForm()}</LoginWrapper>;
  }
}

export default compose(
  inject('AuthStore'),
  observer
)(ResetPasswordLayout);
