/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

// Components
import { CalendarIcon } from '@Utils/constans/icons';

// Utils
import { compose } from '@Utils';
import { formatDate } from '@Utils/formatting';

const useStyles = makeStyles(theme => {
  return {
    wrapper: {
      position: 'relative',
      color: '#A095AE',
      cursor: 'pointer',
      background: '#F2EEF6',
      display: 'inline-block',
      lineHeight: '16px',
      padding: `${theme.spacing()}px 40px ${theme.spacing()}px ${theme.spacing(
        2
      )}px`,
      border: '1px solid #E9E2F4',
      borderRadius: '17px'
    },
    icon: {
      position: 'absolute',
      top: '7px',
      right: '15px'
    }
  };
});

export const Label = ({ label, value, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper} onClick={onClick}>
      {`${label}: `}
      {value || formatDate(Date.now(), 'MM/DD/YYYY')}
      <CalendarIcon className={classes.icon} />
    </div>
  );
};

Label.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default compose(observer)(Label);
