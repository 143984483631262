/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  subheader: {
    fontSize: 12,
    color: '#A095AE',
    marginTop: `-${theme.spacing(3)}px`,
    marginBottom: theme.spacing(5)
  },
  appBar: {
    color: '#2F0A6B',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginBottom: theme.spacing(4)
  },
  tabs: {
    justifyContent: 'space-between'
  },
  rootTabs: {
    minHeight: 20
  },
  indicator: {
    backgroundColor: '#9B51E0'
  },
  tab: {
    minHeight: 20,
    fontSize: 16,
    fontWeight: 'normal',
    textTransform: 'none',
    color: '#A095AE',
    paddingBottom: 0,
    opacity: 1
  },
  tabSelected: {
    color: '#2F0A6B'
  },
  question: {
    lineHeight: '19px',
    margin: theme.spacing(1)
  },
  questionsBlock: {
    height: '130px',
    overflow: 'scroll'
  }
}));

export default useStyles;
