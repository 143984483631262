/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Proptypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Components
import {
  MainContentWrapper,
  PaymentsSummary,
  FromToDateFilter,
  DataTable
} from '@CommonScene';

// Configs
import { tableConfig } from '@Assets/config/tables/membersView';

// Utils
import { compose } from '@Utils';
import { getDateFilterKey } from '@Utils/formatting';

export class MembersView extends Component {
  static propTypes = {
    MembersPaymentsStore: Proptypes.shape({
      aggregatedMemberSummary: Proptypes.object,
      aggregatedMemberTransactions: Proptypes.array,
      aggregatedMemberTransactionsOptions: Proptypes.object,
      setDateRange: Proptypes.func,
      setPage: Proptypes.func,
      setOrder: Proptypes.func,
      clearPaging: Proptypes.func,
      clearAggregatedMemberSummary: Proptypes.func,
      clearAggregatedMemberTransactions: Proptypes.func,
      updateAggregatedSearchCriteria: Proptypes.func,
      getAggregatedMemberSummary: Proptypes.func,
      getAggregatedMemberTransactions: Proptypes.func,
      dateRange: Proptypes.object
    }).isRequired,
    t: Proptypes.func.isRequired
  };

  componentDidMount() {
    const {
      MembersPaymentsStore: {
        setOrder,
        dateRange,
        updateAggregatedSearchCriteria,
        getAggregatedMemberSummary,
        clearAggregatedMemberTransactions,
        getAggregatedMemberTransactions
      }
    } = this.props;

    setOrder({ orderBy: tableConfig.defaultOrderBy });
    clearAggregatedMemberTransactions();
    updateAggregatedSearchCriteria(dateRange);
    getAggregatedMemberSummary();
    getAggregatedMemberTransactions();
  }

  componentWillUnmount() {
    this.clearData();
  }

  fetchTableData = () => {
    const {
      MembersPaymentsStore: { setPage, getAggregatedMemberTransactions }
    } = this.props;

    setPage();
    getAggregatedMemberTransactions();
  };

  updateTableData = searchCriteria => {
    const {
      MembersPaymentsStore: {
        updateAggregatedSearchCriteria,
        getAggregatedMemberTransactions,
        getAggregatedMemberSummary
      }
    } = this.props;

    this.clearData();
    updateAggregatedSearchCriteria(searchCriteria);
    getAggregatedMemberTransactions();
    getAggregatedMemberSummary();
  };

  handleChangeDate = (date, key) => {
    const {
      MembersPaymentsStore: { dateRange, setDateRange }
    } = this.props;

    setDateRange({
      ...dateRange,
      [getDateFilterKey(key)]: date
    });
    this.updateTableData({ [getDateFilterKey(key)]: date });
  };

  clearData = () => {
    const {
      MembersPaymentsStore: {
        clearPaging,
        clearAggregatedMemberSummary,
        clearAggregatedMemberTransactions
      }
    } = this.props;

    clearPaging();
    clearAggregatedMemberSummary();
    clearAggregatedMemberTransactions();
  };

  getHeaderOptions = () => ({
    title: this.props.t('can.members.title')
  });

  render() {
    const {
      MembersPaymentsStore: {
        aggregatedMemberSummary,
        aggregatedMemberTransactions,
        aggregatedMemberTransactionsOptions,
        dateRange
      }
    } = this.props;

    return (
      <MainContentWrapper headerOptions={this.getHeaderOptions()}>
        <FromToDateFilter
          handleChangeDate={this.handleChangeDate}
          dateRange={{ from: dateRange.from_date, to: dateRange.to_date }}
        />
        <PaymentsSummary transactionsSummary={aggregatedMemberSummary} />
        <DataTable
          tableConfig={tableConfig}
          tableData={aggregatedMemberTransactions}
          fetchTableData={this.fetchTableData}
          hasMoreData={!aggregatedMemberTransactionsOptions.last}
        />
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  inject('MembersPaymentsStore'),
  observer
)(MembersView);
