/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';

// Components
import LoginWrapper from '@AuthScene/scenes/common/LoginWrapper';
import Stepper from '@AuthScene/scenes/common/Stepper';
import { SecondaryText } from '@CommonScene/materialUIComponents/ClickableText';
import { PrimaryButton } from '@CommonScene/materialUIComponents/PrimaryButton';

// Material UI
import { Grid, Typography } from '@material-ui/core';

// Styles
import useStyles from './styles';

const SignupWrapper = ({
  title,
  subtitle,
  leftButtonText,
  rightButtonText,
  onLeftButtonClick,
  onRightButtonClick,
  children,
  disabledRightButton,
  withStepper
}) => {
  const classes = useStyles();

  return (
    <LoginWrapper>
      <Grid
        container
        direction="column"
        justify="space-between"
        className={classes.wrapper}
      >
        <Grid item className={classes.header}>
          <Typography className={classes.title}>{title}</Typography>
          {withStepper ? <Stepper /> : null}
          {subtitle ? (
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          ) : null}
        </Grid>
        {children}
        <Grid item>
          <Grid
            container
            justify={onLeftButtonClick ? 'space-between' : 'flex-end'}
            alignItems="center"
          >
            {onLeftButtonClick ? (
              <SecondaryText onClick={onLeftButtonClick}>
                {leftButtonText || 'cancel'}
              </SecondaryText>
            ) : null}
            <PrimaryButton
              onClick={onRightButtonClick}
              color="primary"
              id="login-btn"
              className={classes.button}
              disabled={disabledRightButton}
            >
              {rightButtonText || 'next'}
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </LoginWrapper>
  );
};

SignupWrapper.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  leftButtonText: PropTypes.string,
  rightButtonText: PropTypes.string,
  onLeftButtonClick: PropTypes.func,
  onRightButtonClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
    PropTypes.node
  ]).isRequired,
  disabledRightButton: PropTypes.bool,
  withStepper: PropTypes.bool
};

SignupWrapper.defaultProps = {
  title: 'Step title',
  subtitle: '',
  leftButtonText: 'cancel',
  rightButtonText: 'next',
  disabledRightButton: true,
  withStepper: true
};

export default SignupWrapper;
