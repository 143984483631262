/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import classnames from 'classnames';

// Material UI
import { Grid, List, withStyles } from '@material-ui/core';

// Components
import { BlockWrapper } from '@CommonScene';
import StatusCardContent from '@ParticipantsManagementScene/scenes/Dashboard/components/StatusCardContent';
import RoleCardContent from '@ParticipantsManagementScene/scenes/Dashboard/components/RoleCardContent';

// Utils
import { compose } from '@Utils';
import { addSpacesInNumber } from '@Utils/formatting';
import qs from 'query-string';
import { translation } from '@Utils/translation';

// Styles
import styles from './styles';

export const Card = ({ classes, history, items, title, reportTypeMainKey }) => {
  const total = items.reduce((acc, val) => Number(val.total) + acc, 0);

  const innerContentType = reportTypeMainKey === 'role' ? 'status' : 'role';

  // TODO: Temporary solution! Statuses with the same meaning on the backend has different names
  const statusDictionary = {
    CREATED: 'PREASSIGNED',
    ASSIGNED_WALLET: 'ACTIVE'
  };

  const onSummaryClickHandler = () => {
    history.push({
      pathname: '/participants',
      search: qs.stringify({
        [reportTypeMainKey]: statusDictionary[title] || title
      })
    });
  };

  return (
    <Grid item md={3} className={classes.wrapper}>
      <BlockWrapper skipPadding>
        <div className={classes.headingWrapper}>
          <p
            onClick={onSummaryClickHandler}
            className={classnames(
              classes.bold,
              classes.heading,
              classes[title.replace(' ', '_')]
            )}
          >
            {translation(title, 'statuses')}
          </p>
        </div>
        <List component="ul" className={classes.list}>
          {innerContentType === 'status' ? (
            <RoleCardContent items={items} cardName={title} />
          ) : (
            <StatusCardContent items={items} cardName={title} />
          )}
        </List>
        <Grid
          container
          justify="space-between"
          className={classnames(classes.bold, classes.footer)}
          onClick={onSummaryClickHandler}
        >
          <p>Total</p>
          <p>{addSpacesInNumber(total)}</p>
        </Grid>
      </BlockWrapper>
    </Grid>
  );
};

Card.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  reportTypeMainKey: PropTypes.string.isRequired
};

export default compose(
  withStyles(styles),
  withRouter,
  observer
)(Card);
