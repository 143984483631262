/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import CommonStore from '@Stores/CommonStore';
import AuthStore from '@Stores/AuthStore';
import NetworkStore from '@Stores/NetworkStore';
import RoleStore from '@Stores/RoleStore';
import { AdminRolesStore, UserRolesStore } from '@Stores/RolesStore';
import GroupStore from '@Stores/GroupStore';
import InvitationStore from '@Stores/InvitationStore';
import DeckStore from '@Stores/DeckStore';
import DepositsStore from '@Stores/DepositsStore';
import MembersPaymentsStore from '@Stores/MembersPaymentsStore';
import ServicePaymentsStore from '@Stores/ServicePaymentsStore';
import WithdrawalStore from '@Stores/WithdrawalStore';
import StatisticStore from '@Stores/StatisticStore';
import TermsAndConditionsStore from '@Stores/TermsAndConditionsStore';
import BaseReportingStore, {
  DashboardReportStore,
  TopologyReportStore,
  NodeActivityReportStore,
  NodeActivityDetailsReportStore
} from '@Stores/ReportingStore';
import FilterStore from '@Stores/FilterStore';
import SignUpStore from '@Stores/SignupStore';

export default {
  CommonStore,
  AuthStore,
  NetworkStore,
  AdminRolesStore,
  UserRolesStore,
  RoleStore,
  GroupStore,
  InvitationStore,
  DeckStore,
  DepositsStore,
  MembersPaymentsStore,
  ServicePaymentsStore,
  WithdrawalStore,
  StatisticStore,
  TermsAndConditionsStore,
  BaseReportingStore,
  DashboardReportStore,
  TopologyReportStore,
  NodeActivityReportStore,
  NodeActivityDetailsReportStore,
  FilterStore,
  SignUpStore
};
