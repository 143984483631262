// Core
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    overflow: 'scroll',
    marginBottom: '8px',
    flexGrow: 1,
    height: '1px',
    width: '100%'
  },
  phrase: {
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
    lineHeight: '16px',
    color: '#A095AE',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: 8,
    backgroundColor: '#F8F6FA'
  }
}));

export default useStyles;
