/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action } from 'mobx';

// Providers
import { ReportingProvider } from '@Providers';

// Stores
import CommonStore from '@Stores/CommonStore';
import FilterStore from '@Stores/FilterStore';

// Constans
import { DOCUMENT_FORMATS } from '@Utils/constans/documentFormats';

// Utils
import { groupBy } from 'lodash';

class BaseReportingStore {
  @observable reports = [];

  @observable report = {
    aggregation: [],
    details: []
  };

  @observable details = [];

  @observable pageParams = {};

  @observable dateRange = {
    from: 1546300800000,
    to: Date.now()
  };

  @observable nodeType = 'WALLET';

  @observable nodeTypes = [
    { id: 'WALLET', name: 'Wallet nodes' },
    { id: 'ADMIN', name: 'Admin nodes' }
  ];

  @observable linkToFile = '';

  @action('ReportingStore => assigning report to store')
  _setReport = (report, shouldRewriteData) => {
    if (shouldRewriteData) {
      this.details = report.details;
    } else {
      this.details.replace([...this.details, ...report.details]);
    }
    this.report = report;
  };

  @action('ReportingStore => assigning statuses to store')
  setFilters = filtersKeys => {
    const { setFilters, selectedFilters } = FilterStore;

    const items = filtersKeys.map(filterKey => {
      const getItems = () => {
        // TODO: temporary solution for object type of 'filterKey' param. Need to make FilterContentComponent component more flexible
        switch (typeof filterKey) {
          case 'object':
            return filterKey.items;
          default:
            return Object.keys(groupBy(this.report.details, filterKey));
        }
      };

      const currentFilterKey = filterKey.name || filterKey;

      const isChecked = item => {
        if (item.defaultValue) {
          return item.defaultValue.checked;
        }
        return true;
      };

      return {
        name: currentFilterKey,
        items: getItems().map(filterItem => {
          const filterItemName = filterItem.name || filterItem;
          return {
            name: filterItemName,
            checked: selectedFilters[currentFilterKey]
              ? selectedFilters[currentFilterKey].includes(filterItemName)
              : isChecked(filterItem)
          };
        })
      };
    });
    setFilters(items);
  };

  @action('ReportingStore => get report by id')
  getReport = (reportId, params, shouldRewriteData) => {
    this.resetReport();
    if (shouldRewriteData) {
      this.updatePageParams({
        skip: 0,
        lastPage: false
      });
    }
    const pageParams = params || this.pageParams;
    CommonStore.setPending();
    return ReportingProvider.getReport(reportId, pageParams)
      .then(response => {
        // TODO: Remove hardcoded content[0] in future
        this._setReport(response.content[0], shouldRewriteData);
      })
      .finally(CommonStore.clearPending);
  };

  @action('ReportingStore => set page params')
  setPageParams = params => {
    this.pageParams = params;
  };

  @action('ReportingStore => update page params')
  updatePageParams = newData => {
    this.pageParams = { ...this.pageParams, ...newData };
  };

  @action('ReportingStore => reset details')
  resetDetails = () => {
    this.details = [];
  };

  @action('ReportingStore => reset report')
  resetReport = () => {
    this.report = { aggregation: [], details: [] };
  };

  @action('ReportingStore => set node type')
  setNodeType = nodeType => {
    this.nodeType = nodeType;
    FilterStore.reset();
    this.updatePageParams({ types: [this.nodeType] });
  };

  @action('ReportingStore => update date range')
  updateDateRange = newDate => {
    this.dateRange = { ...this.dateRange, ...newDate };
    this.updatePageParams({
      ts_from: this.dateRange.from,
      ts_to: this.dateRange.to
    });
  };

  @action('ReportingStore => reset date range') resetDateRange = () => {
    this.dateRange = {
      from: 1546300800000,
      to: Date.now()
    };
  };

  @action('ReportingStore => update PDF link')
  updateFileLink = newLink => {
    this.linkToFile = `${newLink}`;
  };

  @action('ReportingStore => get report pdf content')
  getFileContent = (
    reportId,
    params = this.pageParams,
    reportFormat = 'pdf'
  ) => {
    CommonStore.setPending();
    return ReportingProvider.exportReport(reportId, params, reportFormat)
      .then(response => {
        const file = new Blob([response], {
          type: DOCUMENT_FORMATS[reportFormat]
        });
        this.updateFileLink(URL.createObjectURL(file));
      })
      .finally(CommonStore.clearPending);
  };
}

export default new BaseReportingStore();
export const DashboardReportStore = new BaseReportingStore();
export const TopologyReportStore = new BaseReportingStore();
export const NodeActivityReportStore = new BaseReportingStore();
export const NodeActivityDetailsReportStore = new BaseReportingStore();
