import EtherumAbi from '../ethereumjs-abi';
import EtherumUtils from '../ethereumjs-util/dist';

class Blockchain {
  generateEventHash(event, smartContractAddress) {
    const parameterTypes = [
      'address',
      'address',
      'bytes32',
      'bytes32',
      'bytes32',
      'bytes32',
      'string',
      'string',
      'string',
      'uint256',
      'uint256'
    ];
    const method = EtherumAbi.methodID('publish', parameterTypes).toString(
      'hex'
    );
    const parameterValues = [
      event.from,
      event.to,
      event.code,
      event.type,
      event.from_role,
      event.to_role,
      event.correlation_id,
      event.reference_id,
      event.reference_check_sum,
      event.operation_timestamp,
      event.version
    ];
    const params = EtherumAbi.rawEncode(
      parameterTypes,
      parameterValues
    ).toString('hex');
    const dataString = `0x${method}${params}`;
    const hash = `0x${EtherumUtils.keccak256(
      EtherumAbi.rawEncode(
        ['address', 'bytes'],
        [smartContractAddress, EtherumUtils.toBuffer(dataString)]
      )
    ).toString('hex')}`;
    return hash;
  }

  /**
   * Sign hash string (usually hex) with private key via Ethereum
   * @param {string} hash
   * @param {string} privateKey
   * @returns {string} signed string (hex)
   */
  signHash(hash, privateKey) {
    const bufferedKey = Buffer.from(privateKey, 'hex');
    const msg = Buffer.from(hash.replace('0x', ''), 'hex');
    const msgHash = EtherumUtils.hashPersonalMessage(msg);
    const sig = EtherumUtils.ecsign(msgHash, bufferedKey);
    return EtherumUtils.toRpcSig(sig.v, sig.r, sig.s);
  }
}

export default new Blockchain();
