/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import shortid from 'shortid';
import InfiniteScroll from 'react-infinite-scroll-component';

// Material UI
import {
  Table,
  TableHead,
  TableBody,
  CircularProgress,
  withStyles
} from '@material-ui/core';

// Utils
import { compose } from '@Utils';

// Components
import BodyRow from '@CommonScene/DataTable/BodyRow';
import TableHeaderRow from './HeaderRow';
import NoRecordsBlock from './NoRecordsBlock';
import Toolbar from './Toolbar';
import Loader from './Loader';

// Styles
import styles from './styles';

export class DataTable extends Component {
  state = {
    order: this.props.tableConfig.defaultOrder,
    orderBy: this.props.tableConfig.defaultOrderBy
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    tableConfig: PropTypes.object.isRequired,
    tableData: PropTypes.array.isRequired,
    fetchTableData: PropTypes.func.isRequired,
    checkedAllRows: PropTypes.bool,
    hasMoreData: PropTypes.bool,
    CommonStore: PropTypes.object.isRequired
  };

  static defaultProps = {
    hasMoreData: false,
    checkedAllRows: false,
    checkedRows: {}
  };

  handleSorting = (orderBy, callBackFn) => {
    let order = this.props.tableConfig.defaultOrder;

    // Change order direction only if we click on the same sorting cell
    if (this.state.orderBy === orderBy) {
      order = this.state.order === 'desc' ? 'asc' : 'desc';
    }
    this.setState({ order, orderBy });
    callBackFn();
  };

  renderBodyRow = rowData => {
    const { tableConfig } = this.props;
    const {
      rowIdKey,
      extraIdKey,
      handleRowClick,
      rowCells,
      hasActions,
      hasCheckbox,
      onCheckboxChange,
      actionsConfig
    } = tableConfig;

    return (
      <BodyRow
        className="body-row"
        key={shortid.generate()}
        rowData={rowData}
        rowId={rowData[rowIdKey]}
        extraId={rowData[extraIdKey]}
        handleRowClick={handleRowClick}
        rowCells={rowCells}
        hasActions={hasActions}
        hasCheckbox={hasCheckbox}
        onCheckboxChange={onCheckboxChange}
        actionsConfig={actionsConfig}
      />
    );
  };

  render() {
    const {
      classes,
      tableConfig,
      tableData,
      fetchTableData,
      hasMoreData,
      checkedAllRows,
      CommonStore: { pending }
    } = this.props;

    const {
      headings,
      hasActions,
      hasCheckbox,
      onHeaderCheckboxChange
    } = tableConfig;
    const { order, orderBy } = this.state;

    return (
      <InfiniteScroll
        id="infinite-scroll"
        style={{
          overflowY: 'hidden',
          overflowX: 'auto',
          width: '100%',
          padding: '32px',
          border: '1px solid #E9E2F4',
          borderRadius: '24px',
          background: '#FFFFFF'
        }}
        dataLength={tableData.length}
        next={fetchTableData}
        hasMore={hasMoreData}
        loader={
          <div className={classes.containerWrapper}>
            <CircularProgress />
          </div>
        }
      >
        {tableConfig.toolbarConfig ? (
          <Toolbar config={tableConfig.toolbarConfig} />
        ) : null}
        <Table className={classes.table}>
          <TableHead>
            <TableHeaderRow
              id="header-row"
              headings={headings}
              withActions={hasActions}
              hasCheckbox={hasCheckbox}
              onCheckboxChange={onHeaderCheckboxChange}
              checkedCheckbox={checkedAllRows}
              order={order}
              orderBy={orderBy}
              handleSorting={this.handleSorting}
              dataLength={tableData.length}
            />
          </TableHead>
          <TableBody>
            {tableData.map(rowData => this.renderBodyRow(rowData))}
            {pending ? <Loader cellsCount={headings.length} /> : null}
          </TableBody>
        </Table>
        {!tableData.length && !pending ? (
          <NoRecordsBlock id="no-records-block" />
        ) : null}
      </InfiniteScroll>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('CommonStore'),
  observer
)(DataTable);
