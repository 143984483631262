/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

// Material UI
import { PhoneNumberFieldNew, PhoneNumberField } from '@CommonScene';

// Components
import SignupWrapper from '@AuthScene/scenes/common/SignupWrapper';

// Utils
import { compose } from '@Utils';
import { useStore } from '@Utils/hooks';

const CreateAnAccount = ({ history }) => {
  const {
    SignUpStore: { setNextStep, resetSteps }
  } = useStore();

  const onLeftButtonClickHandler = useCallback(() => {
    history.push('/login');
    resetSteps();
  });

  return (
    <SignupWrapper
      onLeftButtonClick={onLeftButtonClickHandler}
      onRightButtonClick={() => setNextStep()}
      title="Create an account"
      disabledRightButton={false}
    >
      <PhoneNumberFieldNew label="Login" />
      <PhoneNumberField
        mask={[/\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/]}
        placeholderChar={'_'}
        label="Invitation code"
      />
    </SignupWrapper>
  );
};

CreateAnAccount.propTypes = {
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  observer
)(CreateAnAccount);
