/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

// Material UI
import { Input, withStyles, FormControl } from '@material-ui/core';

// Components
import IconEyeComponent from '@AuthScene/scenes/common/IconEyeComponent';
import FieldWrapper from '@CommonScene/FieldWrapper';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export const PasswordField = props => {
  const [showPassword, togglePasswordVisability] = useState(false);

  const { label, error, classes, onChange, t, value, ...other } = props;
  // fix for 'React does not recognize the `tReady` prop on a DOM element...'
  const restProps = Object.assign({}, other);
  delete restProps.tReady;

  return (
    <FieldWrapper label={label} className={classes.margin}>
      <FormControl
        className={classNames(classes.formControl, {
          [classes.error]: error
        })}
      >
        <Input
          classes={{ input: classes.inputRoot }}
          error={!!error}
          label={label}
          type={showPassword ? 'text' : 'password'}
          onChange={onChange}
          value={value}
          {...restProps}
        />
      </FormControl>
      <IconEyeComponent
        value={value}
        onClickHandler={() => togglePasswordVisability(!showPassword)}
        openEye={showPassword}
      />
    </FieldWrapper>
  );
};

PasswordField.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  t: PropTypes.func.isRequired
};

PasswordField.defaultProps = {
  onChange: () => {
    // Stub function
  },
  error: null,
  placeholder: '',
  label: 'Your Password'
};

export default compose(
  withStyles(styles),
  withTranslation(),
  observer
)(PasswordField);
