/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Material UI
import { Grid } from '@material-ui/core';

// Component
import { MainContentWrapper } from '@CommonScene';
import ParticipantCard from '@ParticipantsManagementScene/scenes/ParticipantDetails/components/ParticipantCard';
import AdditionalDetails from '@ParticipantsManagementScene/scenes/ParticipantDetails/components/AdditionalDetails';
import JoiningDetails from '@ParticipantsManagementScene/scenes/ParticipantDetails/components/JoiningDetails';

// Utils
import { compose } from '@Utils';

export class ParticipantDetails extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    InvitationStore: PropTypes.object.isRequired
  };

  componentDidMount() {
    const participantId = this.getCurrentParticipantId();
    const { getParticipant } = this.props.InvitationStore;
    getParticipant(participantId).then(() => {});
  }

  componentWillUnmount() {}

  getCurrentParticipantId() {
    return this.props.match.params.id;
  }

  getHeaderOptions() {
    const { t } = this.props;
    return {
      title: t('mwAdmin.invitations.participantDetails')
    };
  }

  render() {
    const { currentParticipant } = this.props.InvitationStore;
    return (
      <MainContentWrapper headerOptions={this.getHeaderOptions()}>
        <Grid container spacing={2}>
          <Grid item md="5">
            <ParticipantCard />
            <JoiningDetails data={currentParticipant.participant_joining_dto} />
          </Grid>
          <Grid item md="7">
            <AdditionalDetails />
          </Grid>
        </Grid>
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  inject('InvitationStore'),
  withRouter,
  observer
)(ParticipantDetails);
