/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action } from 'mobx';
import { debounce } from 'lodash';

// Providers
import { CareProtocolProvider } from '@Providers';

// Stores
import BaseListStore from '@Stores/BaseListStore';
import CommonStore from '@Stores/CommonStore';

// Utils
import { getVersionWithDate } from '@Utils/formatting';

// Constants
import { DELAY_REQUEST } from '@Utils/constans';

export class RolesStore extends BaseListStore {
  constructor(params) {
    super();
    this.orderBy = 'name';
    this.params = params;
  }

  @observable lastPage = false;

  @observable roles = [];

  getRoles = (page = this.page, size = this.rowsPerPage, params = {}) => {
    let queryString = {
      page,
      size,
      sort: `${this.orderBy},${this.order}`,
      ...params
    };
    if (this.searchQuery) {
      queryString = { ...queryString, searchStr: this.searchQuery };
    }

    CommonStore.setPending();
    return this.params
      .getRoles(queryString)
      .then(response => {
        // TODO: hardcoded solution! Need to refactor it on server for getting all roles
        if (size === 3000) {
          // If request return all roles (3000 === "all" for now)
          this._setAllRoles(response.content);
        } else {
          this._setRoles(response);
          this._setPagesParams(response);
        }
        return response;
      })
      .catch(this._handleServerError)
      .finally(CommonStore.clearPending);
  };

  @action('RolesStore => _setRoles') _setRoles = ({ content }) => {
    // Set roles for table with lazy loading
    this.roles = [...this.roles, ...content];
  };

  @action('RolesStore => _setAllRoles') _setAllRoles = roles => {
    // Set all available roles
    this.roles = roles;
  };

  @action('RolesStore => _setPagesParams') _setPagesParams = ({ last }) => {
    this.lastPage = last;
  };

  @action('RolesStore => _updateRoleInList') _updateRoleInList = ({
    id,
    version,
    published
  }) => {
    this.roles.forEach((role, index) => {
      if (role.id === id) {
        this.roles[index].joining_options = {
          ...this.roles[index].joining_options,
          termsAndConditions: {
            version: getVersionWithDate({ version, date: published })
          }
        };
      }
    });
  };

  @action('RolesStore => updateTermsAndConditionsInRoles')
  updateTermsAndConditionsInRoles = ({ content: roles }) => {
    roles.forEach(role => {
      if (role.joining_options && role.joining_options.enabled_terms) {
        CareProtocolProvider.getTermsAndConditionsList({
          roleId: role.id,
          size: 100
        }).then(terms => {
          const currentTerm = terms.content.filter(
            term => term.status === 'ACTIVE'
          );
          this._updateRoleInList({
            id: role.id,
            version: currentTerm[0] && currentTerm[0].version,
            published: currentTerm[0] && currentTerm[0].published
          });
        });
      }
    });
  };

  @action('roleStore => _searchQuery') _searchQuery = () => {
    this._resetRoles();
    this.resetPage();
    this.getRoles();
  };

  _searchWithDebounce = debounce(this._searchQuery, DELAY_REQUEST);

  @action('RolesStore => _resetRoles') _resetRoles = () => {
    this.roles = [];
  };

  setOrder = () => {
    this.resetPage();
    this._changeOrder();
    this._resetRoles();
    this.getRoles().then(this.updateTermsAndConditionsInRoles);
  };

  reset = () => {
    this.resetPage();
    this.clearQuery();
    this._resetRoles();
    this.resetOrder();
  };
}

export const AdminRolesStore = new RolesStore({
  getRoles: CareProtocolProvider.getAdminRoles
});

export const UserRolesStore = new RolesStore({
  getRoles: CareProtocolProvider.getRoles
});
