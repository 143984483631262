/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

// Material UI
import { AppBar, Tabs, Tab, Typography, Grid } from '@material-ui/core';

// Components
import HeaderTitle from '@AuthScene/scenes/common/HeaderTitle';
import ResetPasswordButtons from '@AuthScene/scenes/ResetPassword/components/ResetPasswordButtons';
import TabWrap from '@AuthScene/scenes/ResetPassword/components/TabWrap';
import { TextField } from '@CommonScene';

// Utils
import { useStore } from '@Utils/hooks';
import { compose } from '@Utils';

// Styles
import useStyles from './styles';

function getTabProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
    disableRipple: true
  };
}

const AccountRecovery = () => {
  const { t } = useTranslation();
  const {
    AuthStore: { handleChangeForm, onFieldChange, forms }
  } = useStore();
  const [tab, setTab] = useState(0);
  const classes = useStyles();

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const recoveryForms = ['recoveryFromAnswers', 'recoveryFromMnemonic'];

  const onChange = e => {
    console.log(e.target.name);
    console.log(e.target.value);
    console.log(recoveryForms[tab]);
    onFieldChange(e.target.name, e.target.value, recoveryForms[tab]);
  };

  return (
    <form>
      <HeaderTitle>{t('auth.resetPassword.stage3.heading')}</HeaderTitle>

      <Typography classes={{ body1: classes.subheader }}>
        {t('auth.resetPassword.stage3.description')}
      </Typography>

      <AppBar position="static" classes={{ colorPrimary: classes.appBar }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          classes={{
            root: classes.rootTabs,
            flexContainer: classes.tabs,
            indicator: classes.indicator
          }}
        >
          <Tab
            label={t('auth.resetPassword.stage3.firstTab')}
            classes={{ root: classes.tab, selected: classes.tabSelected }}
            {...getTabProps(0)}
          />
          <Tab
            label={t('auth.resetPassword.stage3.secondTab')}
            classes={{ root: classes.tab, selected: classes.tabSelected }}
            {...getTabProps(1)}
          />
        </Tabs>
      </AppBar>

      <Grid container direction="column">
        <Grid item className={classes.questionsBlock}>
          <TabWrap tab={tab} index={0}>
            <Grid>
              <Typography className={classes.question}>
                What street did you grow up on?
              </Typography>
              <TextField
                value={forms.recoveryFromAnswers.fields.answer_1.value}
                onChange={onChange}
                name="answer_1"
              />
            </Grid>
            <Grid>
              <Typography className={classes.question}>
                What street did you grow up on?
              </Typography>
              <TextField
                value={forms.recoveryFromAnswers.fields.answer_2.value}
                onChange={onChange}
                name="answer_2"
              />
            </Grid>
            <Grid>
              <Typography className={classes.question}>
                What street did you grow up on?
              </Typography>
              <TextField
                value={forms.recoveryFromAnswers.fields.answer_3.value}
                onChange={onChange}
                name="answer_3"
              />
            </Grid>
          </TabWrap>
          <TabWrap tab={tab} index={1}>
            <TextField
              placeholder="Your recovery phrase here..."
              value={forms.recoveryFromMnemonic.fields.phrase.value}
              multiline
              rows="2"
              name="phrase"
              onChange={onChange}
            />
          </TabWrap>
        </Grid>
        <Grid item>
          <ResetPasswordButtons
            nextBtnText={t('common.submit')}
            nextBtnOnclickHandler={event => {
              event.preventDefault();
              // TODO: implement it
              handleChangeForm(4);
            }}
            primaryBtnDisabled={!forms[recoveryForms[tab]].meta.isValid}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default compose(observer)(AccountRecovery);
